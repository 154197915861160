import React from "react"
import { graphql } from "gatsby"
import PrimaryLayout from "../layouts/PrimaryLayout"
import Post from "../components/Post"

export default ({ data }) => {
  return (
	<PrimaryLayout column="col-xs-6 col-md-9">
		{data.allWordpressPost.nodes.map(node => (
			<Post 
				image={node.featured_media ? node.featured_media.source_url : ""}
				title={node.title}
				excerpt={node.excerpt}
				readmore ={node.slug}
			/>
		))}
	</PrimaryLayout>
  )
}

export const query = graphql`
  {
    allWordpressPost {
      nodes {
        title
        slug
        excerpt
        featured_media {
          source_url
        }
      }
    }
  }
`